<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="站点名称" prop="name">
                <el-input v-model="query.name" placeholder="请输入站点名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone" >
                <el-input v-model="query.phone" placeholder="请输入手机号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="站点名称" align="center" prop="name" />
            <el-table-column label="省份" align="center" prop="province" />
            <el-table-column label="城市" align="center" prop="city" />
            <el-table-column label="县（区）" align="center" prop="county" />
            <el-table-column label="详细地址" align="center" prop="address" />
            <el-table-column label="经纬度" align="center" prop="longitude" width="165">
                <template #default="scope">
                    <span style="color: #409EFF">{{ scope.row.longitude }}</span>，<span style="color: #409EFF">{{ scope.row.latitude }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请人" align="center" prop="nickname" />
            <el-table-column label="头像" align="center" prop="headImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center" prop="phone" />

            <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini"
                        type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"
                        v-hasPermi="['ums:site:edit']">编辑</el-button>

                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />

        <!-- 编辑弹窗 -->
        <el-dialog title="编辑站点" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="站点名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入站点名称" ></el-input>
                </el-form-item>
                <el-form-item label="省份" prop="province">
                    <el-input v-model="ruleForm.province" placeholder="请输入省份" ></el-input>
                </el-form-item>
                <el-form-item label="市" prop="city">
                    <el-input v-model="ruleForm.city" placeholder="请输入省份" ></el-input>
                </el-form-item>
                <el-form-item label="区" prop="county">
                    <el-input v-model="ruleForm.county" placeholder="请输入省份" ></el-input>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                    <el-input v-model="ruleForm.address" placeholder="请输入省份" ></el-input>
                </el-form-item>
                <el-form-item label="经度" prop="longitude">
                    <el-input-number v-model="ruleForm.longitude" :min="1" :max="1000" ></el-input-number>
                </el-form-item>
                <el-form-item label="纬度" prop="latitude">
                    <el-input-number v-model="ruleForm.latitude" :min="1" :max="1000" ></el-input-number>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmit('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'SiteAudit',
    data() {
        return {
            // 列表
            list: [],
            // 类型
            dictType: [],
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                status: 1,
                name: '',
                phone: ''
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 状态
            statusDict: {
                '-2': { color: 'danger', label: '禁用' },
                '-1': { color: 'danger', label: '拒绝' },
                '0': { color: 'warning', label: '待审批' },
                '1': { color: 'success', label: '通过' },
            },
            // 弹窗控制器
            dialogVisible: false,
            // 表单数据
            ruleForm: {
                id: null,
                name: '',
                province: '',
                city: '',
                county: '',
                address: '',
                longitude: 0,
                latitude: 0
            },
            // 校验
            rules: {
                name: [
                    { required: true, message: '请输入站点名称', trigger: 'blur' },
                ],
                province: [
                    { required: true, message: '请输入省份', trigger: 'change' }
                ],
                city: [
                    { required: true, message: '请输入城市', trigger: 'change' }
                ],
                county: [
                    { required: true, message: '请输入县(市)', trigger: 'change' }
                ],
                longitude: [
                    { required: true, message: '请选择经度', trigger: 'change' }
                ],
                latitude: [
                    { required: true, message: '请选择纬度', trigger: 'change' }
                ],

            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.siteList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query ={
                current: 1,
                size: 10,
                status: 1,
                name: '',
                phone: ''
            }
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        // 显示编辑弹窗
        handleEdit(data) {
            this.dialogVisible = true
            this.ruleForm = JSON.parse(JSON.stringify(data))
        },
        // 确定编辑
        editSubmit (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    api.editSite(this.ruleForm).then(res => {
                        this.$message.success("修改成功")
                        this.getList()
                        this.dialogVisible = false
                    })
                } else {
                    return false;
                }
            });
        },
        // 关闭弹窗
        handleClose () {
            this.dialogVisible = false
        }
    }
}

</script>